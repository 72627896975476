import React, { ChangeEventHandler, useState } from 'react';
import { Button, Modal, message, Form, Row, Checkbox, Space, Tooltip, Select } from 'antd';
import { FileFilled, ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import axios from 'axios';

import ErrorLogModal from './ErrorLogModal';
import { GlobalCfg } from '../../../../conf';

const { Option } = Select;

interface Props {
    onClose: () => void;
}

enum FileStatus {
    VERIFIED = 'verified',
    FAILED = 'failed',
    UPLOAD = 'upload',
}

const EXPECTED_HEADERS = {
    true: {
        name: 'dataset_schema.csv',
        header: ['Schema URN', 'Description', 'Tags', 'Glossary Terms'],
        data: [
            '<schema_urn>',
            '<description>',
            '"<urn_tags> and make sure that multiple tags use the pipe operator, like this urn_tag_1|urn_tag_2"',
            '"<urn_terms> and make sure that multiple terms use the pipe operator, like this urn_term_1|urn_term_2"',
        ],
    },
    false: {
        name: 'dataset.csv',
        header: ['Dataset URN', 'Description', 'Tags', 'Glossary Terms', 'Domain', 'Owners'],
        data: [
            '<dataset_urn>',
            '<description>',
            '"<urn_tags> and make sure that multiple tags use the pipe operator, like this urn_tag_1|urn_tag_2"',
            '"<urn_terms> and make sure that multiple terms use the pipe operator, like this urn_term_1|urn_term_2"',
            '<domain_urn>',
            '"<owner_email:owner_type> and make sure that multiple owners use the pipe operator and owner_type should be one of them <TECHNICAL_OWNER | BUSINESS_OWNER | DATA_STEWARD | NONE>, like this email:TECHNICAL_OWNER|email:BUSINESS_OWNER"',
        ],
    },
};

function DatasetBulkLoadEntityModal(props: Props) {
    const [form] = Form.useForm();
    const { onClose } = props;

    const [isFormValid, setIsFormValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [errorLogs, setErrorLogs]: any = useState();
    const [isErrorLogModalVisible, setIsErrorLogModalVisible] = useState<boolean>();

    function onBulkImport(values) {
        const formData = new FormData();

        formData.append('file', values.file);
        formData.append('datasetSchemaUpdate', values.datasetSchemaUpdate);
        formData.append('override', values.override);

        // TODO: Need to setup the boiler plate for REST API
        const xsrf = Cookies.get(GlobalCfg.XSRF_TOKEN);
        const xsrfParts = xsrf?.split('|') || [];
        const xsrfHeader = atob(xsrfParts[0]) || '';

        setIsLoading(true);

        axios
            .post('/api/bulkDatasetUpdate', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-EDC-XSRF-TOKEN': xsrfHeader,
                },
            })
            .then((res: any) => {
                message.success(res?.data?.STATUS || 'Uploaded Successfully');
                onClose();
            })
            .catch((error) => {
                message.error(error?.response?.data?.STATUS || error?.message || 'Something went wrong');
            })
            .finally(() => setIsLoading(false));
    }

    /* This method allow us to validate the header of the csv files */
    const validateHeaders = (csvData, expectedHeaders) => {
        const lines = csvData.split('\n');
        const headerRow = lines[0].trim().split(',');

        // Here, we check the expected headers.
        if (JSON.stringify(headerRow) === JSON.stringify(expectedHeaders)) {
            return true;
        }

        // Here, we find the missing headers and unexpected headers.
        const missingHeaders = expectedHeaders.filter((header) => !headerRow.includes(header));
        const unexpectedHeaders = headerRow.filter((header) => !expectedHeaders.includes(header));

        setErrorLogs({ missingHeaders, unexpectedHeaders });
        return false;
    };

    /**
     * This method is used to read the CSV file as text format.
     * @param {object} file - The CSV file object.
     * @returns {Promise} - Returns a promise.
     */
    function readCsvAsText(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = function () {
                resolve(reader.result);
            };

            reader.onerror = function () {
                reject(new Error('File reading failed'));
            };

            // Read the file as text
            reader.readAsText(file);
        });
    }

    // Resets the header and show upload card
    const removeSelectedFile = (e) => {
        e.stopPropagation();
        form.resetFields(['file']);
    };

    // View the Errors on CSV file
    const viewError = (e) => {
        e.stopPropagation();
        setIsErrorLogModalVisible(true);
    };

    /**
     * Render the upload card based on the status.
     * - If status is VERIFIED then it will show the verified card information
     * - If status is FAILED then it will show the failed card information
     * - If status is UPLOAD then it will show the default upload card
     * @returns {JSX.Element} The JSX element representing the upload card.
     */
    const renderUploadCard = (status) => {
        switch (status) {
            case FileStatus.VERIFIED:
                return ({ fileName }: { fileName: string }) => (
                    <>
                        <div className="f-container-title">Verification Successful</div>
                        <Row justify="center" align="middle">
                            <div>
                                <FileFilled style={{ color: '#1CA8DD' }} className="mr-2" />
                                {fileName}
                            </div>
                            <Button type="text" onClick={removeSelectedFile}>
                                Remove
                            </Button>
                        </Row>
                    </>
                );
            case FileStatus.FAILED:
                return ({ fileName }: { fileName: string }) => (
                    <>
                        <div className="f-container-title f-container-title-failed">Verification Failed</div>
                        <div className="f-container-subtitle mt-2">
                            There was a error while uploading the following file.
                        </div>
                        <div className="my-2">
                            <FileFilled style={{ color: '#D96758' }} className="mr-2" />
                            {fileName}
                        </div>
                        <Row justify="center" align="middle">
                            <Button type="primary" className="mr-2" onClick={removeSelectedFile}>
                                Retry
                            </Button>
                            <Button type="default" onClick={viewError}>
                                View Error Log <ArrowRightOutlined />
                            </Button>
                        </Row>
                    </>
                );
            default:
                return ({ onSelect }: { onSelect: ChangeEventHandler<HTMLInputElement> }) => (
                    <>
                        <div className="f-container-title">Drag & drop your csv here</div>
                        <div className="f-container-subtitle mt-2">We will verify it against the template first</div>
                        <Button className="f-btn-sec ma-0 mt-2">OR select File to Import</Button>
                        <input type="file" accept=".csv" onChange={onSelect} aria-label="CSV Bulk Update" />
                    </>
                );
        }
    };

    const DragNDrop = (fieldProps) => {
        const { onChange, value } = fieldProps;

        const [error] = form.getFieldError('file');

        // Here, we get the status of the file to render the UI accordingly.
        let status;
        if (error && value) {
            status = error;
        } else if (value) {
            status = FileStatus.VERIFIED;
        } else {
            status = FileStatus.UPLOAD;
        }

        // Here, we get the rendered UI.
        const Render = renderUploadCard(status);

        // Here, we handle the drag and drop files
        const onDropHandler = (e) => {
            const file = e?.target?.files?.[0];
            if (file?.type?.includes?.('csv')) {
                onChange(file);
            }
        };

        return (
            <div className="f-file-upload-container">
                <div onDrop={onDropHandler}>
                    <Render fileName={value?.name ?? ''} onSelect={onDropHandler} />
                </div>
            </div>
        );
    };

    const downloadSample = () => {
        const { header, data, name } = EXPECTED_HEADERS[form.getFieldValue('datasetSchemaUpdate')];

        // Create the CSV Sample Data
        const csvData = [[header.join(',')], [data.join(',')]];

        // Create a Blob containing the CSV data
        const blob = new Blob([csvData.join('\n')], { type: 'text/csv' });

        // Create a download link
        const link = document.createElement('a');

        // Attach the downloadable link
        link.href = URL.createObjectURL(blob);
        link.download = name;

        // Append the link to the document and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up by removing the link from the document
        document.body.removeChild(link);
    };

    return (
        <>
            <Modal
                open
                title="Bulk Update"
                width={780}
                centered
                closable={false}
                onCancel={onClose}
                footer={
                    <>
                        <Button
                            form="importBulkForm"
                            type="primary"
                            htmlType="submit"
                            disabled={isFormValid}
                            loading={isLoading}
                        >
                            Import
                        </Button>

                        <Button onClick={onClose} type="default">
                            Cancel
                        </Button>
                    </>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    requiredMark="optional"
                    name="importBulkForm"
                    onFinish={onBulkImport}
                    initialValues={{ file: null, datasetSchemaUpdate: 'false', override: false }}
                    onFieldsChange={() =>
                        setIsFormValid(form.getFieldsError().some((field) => field.errors.length > 0))
                    }
                >
                    {/* csv type */}
                    <Form.Item name="datasetSchemaUpdate" label="Select CSV type" required>
                        <Select
                            placeholder="select csv type"
                            aria-label="Select CSV Type"
                            getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        >
                            <Option value="false" tabIndex={0}>
                                Dataset
                            </Option>
                            <Option value="true" tabIndex={0}>
                                Dataset schema
                            </Option>
                        </Select>
                    </Form.Item>

                    {/* drop field */}
                    <Form.Item
                        required
                        name="file"
                        shouldUpdate
                        dependencies={['datasetSchemaUpdate', 'override']}
                        valuePropName="value"
                        validateStatus="error"
                        help=""
                        rules={[
                            ({ getFieldValue }) => ({
                                async validator(_, csvFile) {
                                    try {
                                        // Here, we check if the file is empty, then return an error.
                                        if (!csvFile) {
                                            return await Promise.reject(FileStatus.UPLOAD);
                                        }

                                        // Here, we get the expected headers.
                                        const expectedHeaders: string[] =
                                            EXPECTED_HEADERS[getFieldValue('datasetSchemaUpdate')].header;

                                        // Here, we get the CSV file as text format.
                                        const csvData = await readCsvAsText(csvFile);

                                        // Here, we validate the header from the expected headers.
                                        const result = validateHeaders(csvData, expectedHeaders);

                                        if (result) {
                                            return await Promise.resolve(FileStatus.VERIFIED);
                                        }
                                        return await Promise.reject(FileStatus.FAILED);
                                    } catch (err) {
                                        return await Promise.reject(FileStatus.FAILED);
                                    }
                                },
                            }),
                        ]}
                    >
                        <DragNDrop />
                    </Form.Item>

                    {/* download sample */}
                    <div className="mb-2">
                        <Space align="center">
                            <Button type="link" onClick={downloadSample}>
                                Download Sample CSV
                            </Button>
                            <Tooltip
                                placement="top"
                                title="Upload the CSV file following the provided sample template. Ensure that column placement aligns with the template for accurate data processing."
                                showArrow
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Space>
                    </div>

                    {/* override */}
                    <Space align="center" size={1}>
                        <Form.Item noStyle name="override" valuePropName="checked">
                            <Checkbox>Override</Checkbox>
                        </Form.Item>
                        <Tooltip
                            overlayStyle={{ maxWidth: '200px' }}
                            placement="top"
                            title="Check this option to generate new data and remove existing entries. Leave it unchecked to merge the new metadata with the existing entries."
                            showArrow
                        >
                            <InfoCircleOutlined />
                        </Tooltip>
                    </Space>
                </Form>
            </Modal>
            {isErrorLogModalVisible && (
                <ErrorLogModal onClose={() => setIsErrorLogModalVisible(false)} errorLogs={errorLogs} />
            )}
        </>
    );
}

export default DatasetBulkLoadEntityModal;
