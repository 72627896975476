import React, { useEffect } from 'react';
import { Button, Form, Modal, Input, Typography, message } from 'antd';
import { useUpdateGlossaryPropertyMutation } from '../../../../../graphql/glossary.generated';
import { useUpdateEntityPropertyMutation } from '../../../../../graphql/dataset.generated';
import { useUpdateDataProductPropertyMutation } from '../../../../../graphql/dataProduct.generated';

export enum OperationType {
    ADD,
    EDIT,
}

type AddOrEditPropertyProps = {
    urn?: string;
    type: string;
    isModalVisible: boolean;
    setIsModalVisible: (event) => void;
    operationType?: OperationType;
    editModalData?: any;
    refetch: () => void;
};

export const AddOrEditProperty = ({
    urn,
    type,
    isModalVisible,
    setIsModalVisible,
    operationType,
    editModalData,
    refetch,
}: AddOrEditPropertyProps) => {
    const [form] = Form.useForm();

    const [updateGlossaryProperty] = useUpdateGlossaryPropertyMutation();
    const [useUpdateEntityProperty] = useUpdateEntityPropertyMutation();
    const [updateDataProductPropertyMutation] = useUpdateDataProductPropertyMutation();

    const operationTxt = operationType === OperationType.ADD ? 'Add New' : 'Edit';
    const isEdit = operationType === OperationType.EDIT;

    useEffect(() => {
        if (isEdit) {
            form.setFieldsValue(editModalData);
        } else {
            form.resetFields();
        }
    }, [editModalData, form, isEdit]);

    const onClose = () => {
        // Checks if the operation type is equal to the ADD operation type.
        if (!isEdit) {
            form.resetFields();
        }

        // Closes the modal.
        setIsModalVisible(false);
    };

    const getAddOrEditMutationFunc = (entityType) => {
        switch (entityType) {
            case 'DATA_PRODUCT':
                return updateDataProductPropertyMutation;
            case 'GLOSSARY_TERM':
                return updateGlossaryProperty;
            default:
                return useUpdateEntityProperty;
        }
    };

    const getEntityTxt = (entityType) => {
        switch (entityType) {
            case 'DATA_PRODUCT':
                return 'Data Product';
            case 'GLOSSARY_TERM':
                return 'Glossary';
            default:
                return 'Entity';
        }
    };

    /**
     * Adds or updates a glossary property.
     */
    const addOrUpdateGlossaryProperty = async () => {
        const values = await form.validateFields();
        const addOrEditMutation = getAddOrEditMutationFunc(type);
        const entityTxt = getEntityTxt(type);

        const text = operationType === OperationType.ADD ? 'Added' : 'Updated';

        // Checks if the operation type is equal to the ADD operation type.
        try {
            await addOrEditMutation({
                variables: {
                    input: {
                        urn: urn || '',
                        customProperties: [values],
                    },
                },
            });
            refetch();
            message.destroy();
            message.success({ content: `Property ${text} Successfully`, duration: 2 });
            onClose();
        } catch (e: unknown) {
            message.destroy();
            if (e instanceof Error) {
                message.error({
                    content: `Failed to ${operationTxt} ${entityTxt} Property: \n ${e.message || ''}`,
                    duration: 2,
                });
            }
        }
    };

    return (
        <Modal
            title={`${operationTxt} Property`}
            visible={isModalVisible}
            onCancel={onClose}
            destroyOnClose
            centered
            footer={
                <>
                    <Button type="primary" onClick={addOrUpdateGlossaryProperty}>
                        {operationType === OperationType.ADD ? 'Add' : 'Save'}
                    </Button>
                    <Button type="default" onClick={onClose}>
                        Cancel
                    </Button>
                </>
            }
        >
            <Form form={form} layout="vertical" colon={false} requiredMark={false}>
                <Form.Item
                    name="key"
                    className="mb-0"
                    label={
                        <Typography.Text strong>
                            Name <span className="f-color-risk-very-high">*</span>
                        </Typography.Text>
                    }
                    rules={[
                        {
                            required: true,
                            message: 'A Name is required.',
                        },
                        { min: 2, max: 50, message: 'Name must be between 2 and 50 characters' },
                    ]}
                >
                    <Input
                        placeholder="A name for the property"
                        autoFocus
                        disabled={operationType === OperationType.EDIT}
                    />
                </Form.Item>
                <Typography.Paragraph type="secondary" className="py-1">
                    Give the property a convenient name.
                </Typography.Paragraph>

                <Form.Item
                    name="value"
                    className="mb-0"
                    label={
                        <Typography.Text strong>
                            Value <span className="f-color-risk-very-high">*</span>
                        </Typography.Text>
                    }
                    rules={[
                        {
                            required: true,
                            message: 'A value is required.',
                        },
                    ]}
                >
                    <Input placeholder="The value for the property property" />
                </Form.Item>
                <Typography.Paragraph type="secondary" className="py-1">
                    Assign the property a value.
                </Typography.Paragraph>
            </Form>
        </Modal>
    );
};
